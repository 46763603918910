.speedBox {
  margin: 10px;
  width: 150px;
  height: 150px;
  color: red;
  border: 1px solid rebeccapurple;
  border-radius: 10px;
}

.legend {
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 6px 8px;
  width: 120px;
  line-height: 18px;
  color: #555;
}

.color-box {
  margin: 10px;
  width: 20px;
  height: 20px;
  background-color: red;
  border: 1px solid rebeccapurple;
}

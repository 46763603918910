/*!
 * https://github.com/arqex/react-datetime
 */
.rdtCounter .rdtBtn,
.rdtNext span,
.rdtPrev span {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
.rdtPicker .rdtTimeToggle {
  text-align: center;
}
.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover {
  background: #eee;
  cursor: pointer;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover,
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover,
.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  cursor: not-allowed;
  background: 0 0;
  color: #999;
}
.rdtPicker td.rdtNew,
.rdtPicker td.rdtOld {
  color: #999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td span.rdtOld {
  color: #999;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}
.rdtNext span,
.rdtPrev span {
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eee;
}
.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}
.rdtPicker button {
  border: none;
  background: 0 0;
  cursor: pointer;
}
.rdtCounter .rdtBtn:hover,
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}
.rdtPicker button:hover {
  background-color: #eee;
}
.rdtPicker thead button {
  width: 100%;
  height: 100%;
}
td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
.rdtCounters {
  display: inline-block;
}
.rdtCounters > div {
  float: left;
}
.rdtCounter {
  height: 100px;
  width: 40px;
}
.rdtCounterSeparator {
  line-height: 100px;
}
.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}
.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}
.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}
.rdt > input {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.2142em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67861429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;
  transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;
  box-shadow: none;
}

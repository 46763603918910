/*!
 * https://github.com/arqex/react-datetime
 */
.rdtCounter .rdtBtn,
.rdtNext span,
.rdtPrev span {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
.rdtPicker .rdtTimeToggle {
  text-align: center;
}
.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover {
  background: #eee;
  cursor: pointer;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover,
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover,
.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  cursor: not-allowed;
  background: 0 0;
  color: #999;
}
.rdtPicker td.rdtNew,
.rdtPicker td.rdtOld {
  color: #999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td span.rdtOld {
  color: #999;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}
.rdtNext span,
.rdtPrev span {
  display: block;
  -webkit-user-select: none;
  user-select: none;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eee;
}
.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}
.rdtPicker button {
  border: none;
  background: 0 0;
  cursor: pointer;
}
.rdtCounter .rdtBtn:hover,
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}
.rdtPicker button:hover {
  background-color: #eee;
}
.rdtPicker thead button {
  width: 100%;
  height: 100%;
}
td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
.rdtCounters {
  display: inline-block;
}
.rdtCounters > div {
  float: left;
}
.rdtCounter {
  height: 100px;
  width: 40px;
}
.rdtCounterSeparator {
  line-height: 100px;
}
.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  user-select: none;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}
.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}
.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}
.rdt > input {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.2142em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67861429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;
  box-shadow: none;
}

.ui.wide.right.sidebar {
  width: 400px;
  background-color: #f5f7fa;
  padding-top: 10px;
}

.slogan,
body {
  display: block;
}

body,
html {
  width: 100%;
}

.bg,
html {
  position: relative;
}

.slogan,
.testimonial {
  text-align: center;
}

.bg,
.parallax {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.login-flow {
  background: url('/images/home.jpg') center no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  color: #fff;
  min-height: 100vh;
  padding-top: 5%;
}

html {
  height: 100%;
}

.ui.menu.fluid,
.ui.vertical.menu.fluid {
  width: 100% !important;
  height: auto;
}

.bg {
  background: url('/images/home.jpg') center no-repeat;
  height: 540px !important;
  background-clip: border-box;
  background-size: auto;
  color: #fff;
}

.slogan {
  margin: 10% 0;
  width: 100%;
}

.slogan h1 {
  font-weight: bolder;
  font-size: 5vw;
}

.social {
  margin: 1rem;
}

.alice-carousel__wrapper {
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
}

.alice-carousel {
  position: relative;
  min-height: 18rem;
  width: 100%;
  padding: 1rem 0;
  margin: 0;
}

.alice-carousel__next-btn,
.alice-carousel__prev-btn {
  position: relative;
  margin: 0 3%;
}

.alice-carousel__next-btn-item,
.alice-carousel__prev-btn-item {
  color: #00b5ad;
  border: 1px solid #00b5ad;
}

.alice-carousel__next-btn-item:hover,
.alice-carousel__prev-btn-item:hover {
  color: #e0321c;
  border: 1px solid #e0321c;
}

.alice-carousel__dots-item.__active,
.alice-carousel__dots-item:hover {
  background-color: #00b5ad;
}

.alice-carousel__dots {
  display: inline-block;
  margin: auto;
  padding: 1px;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.parallax {
  background-image: url('/images/home.jpg');
  height: 92vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.scrollbar {
  max-width: 100%;
  overflow: hidden;
}

.footer {
  position: relative;
}

.copyright,
.footer {
  background: #212121;
}

#client-anim {
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 0.3s;
}

#feature-anim,
#label-anim,
#price-anim {
  -webkit-animation-duration: 2s;
  -webkit-animation-delay: 0.5s;
}

.bg-service {
  box-shadow: inset 0 0 0 1000px rgba(158, 158, 158, 0.02);
  border-top: 1px rgba(158, 158, 158, 0.2) solid;
}

.bg-service-bottom {
  border-bottom: 1px rgba(158, 158, 158, 0.2) solid;
}

.service-left {
  margin: 5% 0 !important;
  text-align: right;
}

.service-right {
  margin: 1% 0 !important;
  text-align: left;
}

.center-header,
.copyright,
.footer,
.price {
  text-align: center;
}

.price {
  font-size: 6vw;
}

.price-item {
  margin: 1rem 0;
}

.center-header {
  margin: auto;
  display: block;
}

.copyright {
  padding-top: 1rem;
  height: 4rem;
  color: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}

.footer,
.item > a {
  color: black;
  text-decoration: none;
  padding: 5px 0;
}

.footer {
  padding: 2rem 12rem;
  bottom: 0;
  width: 100%;
}

.gps-ring-box {
  position: relative;
  display: inline-block;
}

.gps-ring-animate {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  animation: pulsate 1s ease-in infinite;
}

.gps-ring-border-stop {
  background-color: #6d6f6f;
}
.gps-ring-border-5km {
  background-color: #fcb66f;
}
.gps-ring-border-25km {
  background-color: #6db6fe;
}
.gps-ring-border-60km {
  background-color: #4db64c;
}
.gps-ring-border-80km {
  background-color: #b74dfe;
}
.gps-ring-border-110km {
  background-color: #db4d4c;
}

@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 1;
  }
  25% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.9;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.7;
  }
  75% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.5;
  }
  100% {
    opacity: 0.3;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

.ui.icon.input {
  width: 100% !important;
}

.ui.custom-menu {
  display: flex;
  margin: 1rem 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background: #001529;
  font-weight: 400;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0;
  height: 3.857143em;
}

.ui.segment {
  border-radius: 0;
}

.ui.button.menu-custom-btn {
  background: #001529;
  color: #fff;
  border-radius: 0;
  transition: background 0.5s, color 0.5s;
  -webkit-transition: background 0.5s, color 0.5s;
  -moz-transition: background 0.5s, color 0.5s;
  -o-transition: background 0.5s, color 0.5s;
  padding: 0;
  animation: shake 2s 1s;
}

.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  top: 15rem;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 5px;
}

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin: 0 0 3.5rem -0.5rem;
}

.nav-button,
.rdtPicker {
  position: relative;
}

/* @media only screen and (min-width: 1200px) {
  .ui.modal {
    width: 900px;
    margin: 0 0 0 -450px;
    height: 650px;
  }
} */

@media only screen and (min-width: 992px) {
  .ui.search.dropdown .menu {
    max-height: 500px;
  }
}

.rdt > input {
  width: 100%;
}

b {
  font-weight: 500;
}

.nav-button {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.65rem;
}

#show,
#slider {
  position: absolute;
}

#show {
  z-index: 1000;
  top: 1px;
  border-radius: 0;
  height: 3.67rem;
  background: #424242;
  transition: background 0.5s, color 0.5s;
  -webkit-transition: background 0.5s, color 0.5s;
  -moz-transition: background 0.5s, color 0.5s;
  -o-transition: background 0.5s, color 0.5s;
}

#show:hover,
.ui.button.menu-custom-btn:hover {
  background: #001529;
  color: #001529;
}

.strength {
  margin-top: 3px;
}

#slider {
  top: -520px;
  z-index: 10;
  min-width: 20%;
}

.checkbox,
.span {
  position: relative;
}

.open {
  top: 2.8rem !important;
}

.transition {
  transition: top 0.5s ease-out;
}

.ui.menu {
  text-align: left;
}

.ui.button.no-radius-button {
  border-radius: 0;
  background: #00b5ad;
  color: #fff;
}

.ui.inverted.progress .bar > .progress {
  color: #000;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #00b5ad;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.select-dropdown dd ul li a:hover,
.select-dropdown dt a {
  background-color: #fff;
}

.rdtPicker td.rdtToday:before {
  border-bottom: 7px solid #00b5ad;
}

.select-dropdown dd,
.select-dropdown dt {
  margin: 0;
  padding: 0;
}

.select-dropdown a,
.select-dropdown a:visited {
  color: #fff;
  text-decoration: none;
  outline: 0;
}

.select-dropdown dt a {
  display: block;
  border: 1px solid #96c8da;
  padding: 0.8rem;
  overflow: hidden;
  color: #000;
}

.multiSel span,
.select-dropdown dt a span {
  cursor: pointer;
  display: inline-block;
}

.select-dropdown dd ul {
  background-color: #fff;
  color: #000;
  display: none;
  border-left: 1px solid #96c8da;
  border-right: 1px solid #96c8da;
  border-bottom: 1px solid #96c8da;
  list-style: none;
  overflow-y: scroll !important;
  margin: 0;
  padding: 1rem;
  min-height: 400px;
  max-height: 400px;
}

.select-dropdown span.value {
  display: none;
}

.select-dropdown dd ul li a {
  padding: 0.5rem;
  display: block;
}

.btn,
.checkbox {
  display: inline-block;
}

.animated-icon {
  width: 20px;
  height: 20px;
}

.toBold {
  font-weight: bolder;
  color: #d50000;
}

.ajs-ok {
  color: #000 !important;
  background: #fff !important;
}

.btn,
.btn-link {
  font-weight: 400;
}

.toast {
  background: red;
}

.btn,
.btn-danger.active,
.btn-danger:active,
.btn-default.active,
.btn-default:active,
.btn-info.active,
.btn-info:active,
.btn-primary.active,
.btn-primary:active,
.btn-success.active,
.btn-success:active,
.btn-warning.active,
.btn-warning:active,
.form-control,
.open .dropdown-toggle.btn-danger,
.open .dropdown-toggle.btn-default,
.open .dropdown-toggle.btn-info,
.open .dropdown-toggle.btn-primary,
.open .dropdown-toggle.btn-success,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}

.multiselect-container > li > a > label.checkbox,
.multiselect-container > li > a > label.radio {
  margin-left: -2.2em;
}

.checkbox {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 0;
  vertical-align: baseline;
  font-style: normal;
  min-height: 17px;
  font-size: 1rem;
  line-height: 17px;
  min-width: 17px;
}

.btn {
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn-block,
.form-control {
  display: block;
  width: 100%;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: dotted thin;
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: -2px;
}

.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: 0.65;
}

.btn-link,
.cross-button:hover {
  cursor: pointer;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default.active,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open .dropdown-toggle.btn-default {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
}

.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default[disabled].active,
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #3276b1;
  border-color: #285e8e;
}

.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #428bca;
  border-color: #357ebd;
}

.btn-primary .badge {
  color: #428bca;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success.active,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #47a447;
  border-color: #398439;
}

.btn-success.disabled,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled],
.btn-success[disabled].active,
.btn-success[disabled]:active,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info.active,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #39b3d7;
  border-color: #269abc;
}

.btn-info.disabled,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled],
.btn-info[disabled].active,
.btn-info[disabled]:active,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ed9c28;
  border-color: #d58512;
}

.btn-warning.disabled,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled],
.btn-warning[disabled].active,
.btn-warning[disabled]:active,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #d2322d;
  border-color: #ac2925;
}

.btn-danger.disabled,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled],
.btn-danger[disabled].active,
.btn-danger[disabled]:active,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  color: #428bca;
  border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  box-shadow: none;
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
  color: #2a6496;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  color: #999;
  text-decoration: none;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-block {
  padding-right: 0;
  padding-left: 0;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%;
}

.ui.form .fields {
  margin: 0;
}

.span {
  top: 0.5em;
  left: 2.5em;
}

input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}

input[type='checkbox']:focus {
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: -2px;
}

.form-control {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control[disabled],
.form-control[readonly],
.form-group {
  margin-bottom: 15px;
}

.caret,
.input-group-addon,
.multiselect-clear-filter {
  display: none;
}

.ui.grid {
  margin: 0;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  border-color: #96c8da;
  max-height: 280px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
}

.asset-submit {
  animation: glowing 2s 6;
}

.open > .dropdown-menu {
  left: -150px;
  width: 20rem;
}

.asset-options {
  padding: 0 1em !important;
}

.cross-button {
  position: absolute;
  left: 10px;
  top: 20px;
}

.report-cross-button {
  position: absolute;
  right: 5px;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
  .ui.wide.right.sidebar {
    width: 250px;
  }
  .rdtPicker,
  .ui.grid > [class*='six wide'].column,
  .ui.grid > [class*='ten wide'].column {
    width: 100%;
  }
  .footer {
    display: none;
  }
  .copyright {
    padding: 1.2rem 2rem;
    bottom: 0;
  }
  .ui.grid > * {
    padding-left: 0;
  }
  .asset-options {
    padding-right: 1.3em !important;
  }
  .timer-btn {
    width: 170px;
    padding-top: 5px !important;
  }
  .select-asset-btn {
    width: 95px !important;
    margin-left: 10px !important;
  }
  .copyright,
  .ui[class*='four column'].grid > .column:not(.row),
  .ui[class*='four column'].grid > .row > .column,
  .ui[class*='two column'].grid > .column:not(.row),
  .ui[class*='two column'].grid > .row > .column {
    width: 100%;
  }
  .nav-logout {
    display: none !important;
  }
  .open > .dropdown-menu {
    left: -120px;
  }
  .copyright {
    position: relative;
  }
  body {
    max-height: 500px !important;
  }
}

.ui.fluid.card {
  min-height: 220px;
}

.engine-container {
  height: 50px;
  margin-top: 15px;
  display: flex;
  padding: 15px;
  background-color: #f4f8fb;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.engine-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.engine-container strong {
  margin-right: 15px;
  text-align: center;
  padding-left: 20%;
}
.date-time {
  font-size: 14px;
  padding-top: 12px;
  margin-left: 25px;
  display: -webkit-inline-box;
  display: -webkit-box;
  align-content: space-around;
}

.date-time-report {
  border: 1px solid #ced4da;
  background-color: white;
  border-radius: 2px;
  font-size: 14px;
  padding: 5px;
  display: -webkit-inline-box;
  display: -webkit-box;
  align-content: space-around;
  margin: 10px 0;
}

#animation-overlay {
  font-size: 20px;
  position: fixed !important;
  z-index: 99 !important;
  background: #00b5ad;
  width: 100px;
  bottom: 30px !important;
  left: 40% !important;
}

.mapOverLay {
  position: fixed !important;
  z-index: 99999 !important;
  bottom: 1px !important;
  left: 25% !important;
  padding: 3px 5px;
  color: #fefafb;
  line-height: 2.2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mapOverLay-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-color: rgb(27, 28, 29);
  border-radius: 4px;
}

.mapOverLay h5 {
  font-size: 15px;
  color: white;
}

.mapLayout {
  padding: 5px;
}

.overlayHead {
  width: 20px;
  color: #fefafb;
}

.navbar-color {
  color: #231f20;
}

.assetColor .multiselect-selected-text {
  font: 600, 'Lucida Grande', verdana, sans-serif;
  font-size: 15px;
  color: white;
}

.font-color {
  font: 600, 'Lucida Grande', verdana, sans-serif;
  font-size: 15px;
  color: white;
}

.item {
  font: 600, 'Lucida Grande', verdana, sans-serif;
  font-size: 15px;
  color: white;
}

.ant-calendar-time-picker-select:last-child {
  display: none;
}

.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-select :nth-child(3) {
  display: none;
}

.meter-distance {
  color: #7a7581;
  padding-left: 5px;
  display: block;
}
.google-login button {
  background-color: red !important;
}

#GeofenceMap {
  z-index: 100;
  height: 70vh;
}

#drawgeomap {
  z-index: 100;
  height: 84vh;
}

.leaflet-draw-toolbar .leaflet-bar {
  display: none;
}

.ui.menu.fixed {
  z-index: 150;
}

/*   this portion is for leaflet draw .   */

.sr-only {
  display: none;
}

/* ================================================================== */
/* Toolbars
/* ================================================================== */

.leaflet-draw-section {
  position: relative;
}

.leaflet-draw-toolbar {
  margin-top: 12px;
}
.leaflet-draw-toolbar-top {
  margin-top: 0;
}

.leaflet-draw-toolbar-notop a:first-child {
  border-top-right-radius: 0;
}

.leaflet-draw-toolbar-nobottom a:last-child {
  border-bottom-right-radius: 0;
}

.leaflet-draw-toolbar a {
  background-image: url('/images/spritesheet.png');
  background-repeat: no-repeat;
}

.leaflet-retina .leaflet-draw-toolbar a {
  background-image: url('/images/spritesheet-2x.png');
  background-size: 270px 30px;
}

.leaflet-draw a {
  display: block;
  text-align: center;
  text-decoration: none;
}

/* ================================================================== */
/* Toolbar actions menu
/* ================================================================== */

.leaflet-draw-actions {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 26px; /* leaflet-draw-toolbar.left + leaflet-draw-toolbar.width */
  top: 0;
  white-space: nowrap;
}

.leaflet-right .leaflet-draw-actions {
  right: 26px;
  left: auto;
}

.leaflet-draw-actions li {
  display: inline-block;
}

.leaflet-draw-actions li:first-child a {
  border-left: none;
}

.leaflet-draw-actions li:last-child a {
  border-radius: 0 4px 4px 0;
}

.leaflet-right .leaflet-draw-actions li:last-child a {
  border-radius: 0;
}

.leaflet-right .leaflet-draw-actions li:first-child a {
  border-radius: 4px 0 0 4px;
}

.leaflet-draw-actions a {
  background-color: #919187;
  border-left: 1px solid #aaa;
  color: #fff;
  font: 11px/19px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 28px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
}

.leaflet-draw-actions-bottom {
  margin-top: 0;
}

.leaflet-draw-actions-top {
  margin-top: 1px;
}

.leaflet-draw-actions-top a,
.leaflet-draw-actions-bottom a {
  height: 27px;
  line-height: 27px;
}

.leaflet-draw-actions a:hover {
  background-color: #a0a098;
}

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 26px;
  line-height: 26px;
}

/* ================================================================== */
/* Draw toolbar
/* ================================================================== */

.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: -2px -2px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -31px -2px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -62px -2px;
}
.google-login button {
  background-color: red !important;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -92px -2px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -122px -2px;
}

/* ================================================================== */
/* Edit toolbar
/* ================================================================== */

.leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -152px -2px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -182px -2px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -212px -2px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -242px -2px;
}

/* ================================================================== */
/* Drawing styles
/* ================================================================== */

.leaflet-mouse-marker {
  background-color: #fff;
  cursor: crosshair;
}

.leaflet-draw-tooltip {
  background: rgb(54, 54, 54);
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  font: 12px/18px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-left: 20px;
  margin-top: -21px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
}

.leaflet-draw-tooltip:before {
  border-right: 6px solid black;
  border-right-color: rgba(0, 0, 0, 0.5);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: '';
  position: absolute;
  top: 7px;
  left: -7px;
}

.leaflet-error-draw-tooltip {
  background-color: #f2dede;
  border: 1px solid #e6b6bd;
  color: #b94a48;
}

.leaflet-error-draw-tooltip:before {
  border-right-color: #e6b6bd;
}

.leaflet-draw-tooltip-single {
  margin-top: -12px;
}

.leaflet-draw-tooltip-subtext {
  color: #f8d5e4;
}

.leaflet-draw-guide-dash {
  font-size: 1%;
  opacity: 0.6;
  position: absolute;
  width: 5px;
  height: 5px;
}

/* ================================================================== */
/* Edit styles
/* ================================================================== */

.leaflet-edit-marker-selected {
  background: rgba(254, 87, 161, 0.1);
  border: 4px dashed rgba(254, 87, 161, 0.6);
  border-radius: 4px;
}

.leaflet-edit-move {
  cursor: move;
}

.leaflet-edit-resize {
  cursor: pointer;
}

/* ================================================================== */
/* Old IE styles
/* ================================================================== */

.leaflet-oldie .leaflet-draw-toolbar {
  border: 3px solid #999;
}

.leaflet-oldie .leaflet-draw-toolbar a {
  background-color: #eee;
}

.leaflet-oldie .leaflet-draw-toolbar a:hover {
  background-color: #fff;
}

.leaflet-oldie .leaflet-draw-actions {
  left: 32px;
  margin-top: 3px;
}

.leaflet-oldie .leaflet-draw-actions li {
  display: inline;
  zoom: 1;
}

.leaflet-oldie .leaflet-edit-marker-selected {
  border: 4px dashed #fe93c2;
}

.leaflet-oldie .leaflet-draw-actions a {
  background-color: #999;
}

.leaflet-oldie .leaflet-draw-actions a:hover {
  background-color: #a5a5a5;
}

.leaflet-oldie .leaflet-draw-actions-top a {
  margin-top: 1px;
}

.leaflet-oldie .leaflet-draw-actions-bottom a {
  height: 28px;
  line-height: 28px;
}

.leaflet-oldie .leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 27px;
  line-height: 27px;
}

/*  leaflet draw end */

/*
background color : #12202F
font color : #FEFAFB
result color : #0B7E3D

all black color : #001529
#231F20
all whie color will be : #F2F5F7
timer
*/
/* Css from azmul hossain */
.ui.thin.left.sidebar {
  width: 200px;
}
/* Spinner */

.sk-folding-cube {
  margin: 250px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00b5ad;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

/* profile css */
.dot {
  height: 5px;
  width: 5px;
  background-color: #42b72a;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 5px;
}

.minimal-information-header {
  margin-bottom: 0px;
  font-size: 15px;
  margin-top: 20px !important;
}
.first-panel {
  margin-left: -40px;
}

.minimal-information {
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
}

.border-right {
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}

.border-left {
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}

.margin-left-10 {
  margin-left: 10px;
}
.padding-left-0 {
  padding-left: 0px;
}
.width-200 {
  width: 200px;
}
.profile-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
.middle-description {
  height: 155px;
  background: rgba(241, 241, 241, 1);
  margin-bottom: -38px;
  margin-top: -70px;
}
.middle-information {
  padding-top: 40px;
}
.middle-information p {
  margin-bottom: 7px;
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
}

.ui.secondary.menu .active.item {
  box-shadow: none;
  background: rgba(255, 255, 255);
  border-radius: 0;
}

.profile-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.profile-table td,
.profile-table th {
  text-align: left;
  padding: 4px;
}

.profile-table tr:nth-child(even) {
  background-color: rgba(241, 241, 241, 1);
}

.right-dropdown-avatar {
  padding: 7px !important;
  border-radius: 50% !important;
  border-radius: 50% !important;
  background-color: #001529 !important;
  /* border: 1px solid white !important; */
}

.right-dropdown-avatar i {
  font-size: 22px !important;
  /* margin-top: 5px !important; */
}

/* Date Picker */

.react-datepicker-popper {
  z-index: 10 !important;
}

.react-datepicker__input-container input {
  width: 185px;
  text-align: center;
  border: none;
  border-radius: 4px;
  padding: 4px 4px;
}

/* Open Right Panel */
.open-right-panel {
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: #fff;
  z-index: 999;
  font-size: 25px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.open-right-panel i {
  color: rgba(0, 0, 0, 0.8);
}

/* Open Live Report Panel */
.open-down-panel {
  position: absolute;
  bottom: 100px;
  right: 10px;
  background-color: #fff;
  z-index: 999;
  font-size: 25px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.open-down-panel i {
  color: rgba(0, 0, 0, 0.8);
}

.zoom-out-panel {
  position: absolute;
  bottom: 150px;
  right: 10px;
  background-color: #fff;
  z-index: 999;
  font-size: 25px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.zoom-out-panel i {
  color: rgba(0, 0, 0, 0.8);
}

.SpeedMeter {
  font-size: 14px;
  position: absolute !important;
  background-color: rgba(236, 236, 236, 1);
  z-index: 99999 !important;
  bottom: 40px !important;
  left: 10px !important;
  border-radius: 4px;
}

.speedChart {
  position: absolute;
  bottom: 1px;
  right: 10%;
  left: 10%;
  z-index: 9999999;
}

.-ratio {
  text-align: center;
  margin-bottom: 5px;
}

@media only screen and (max-width: 900px) {
  .open-down-panel {
    bottom: 20rem;
  }

  .responsive-date-menu {
    display: block !important;
  }
  .ui.wide.right.sidebar {
    width: 304px;
  }

  .mapOverLay {
    left: 3% !important;
    right: 3% !important;
  }

  .animateControlPanel {
    width: 160px;
    height: 22px !important;
    padding-right: 0px !important;
    padding-top: 0;
  }
  .SpeedMeter {
    font-size: 12px;
    top: 25px !important;
    bottom: 0;
    height: 170px;
  }

  .speedChart {
    bottom: 5px;
    right: 2%;
    left: 2%;
  }
}

/* @media only screen and (max-width: 640px) {
  .mapOverLay {
    width: 350px;
    left: 5% !important;
  }
} */

body {
  background-color: #e6ecf0;
}

.no-data {
  background-color: #e6ecf0;
  text-align: center;
  padding: 20px 10px;
}

.year {
  padding: 5px 10px;
  margin: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(20, 33, 45, 0.5);
  cursor: pointer;
}

.year-active {
  padding: 5px 10px;
  margin: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(20, 33, 45, 0.5);
  background-color: #2185d0;
  cursor: pointer;
  color: white;
}

.year:hover {
  background-color: wheat;
}

.monthly-bill {
  background-color: #e6ecf0;
  margin-top: 84px;
  margin-left: 10px;
  margin-right: 10px;
}

.sms-panel {
  margin: 20px;
  background-color: #ffffff;
  overflow: auto;
  border-radius: 9px;
}

.sms-pack {
  border: 9px;
  margin: 10px;
  padding-top: 0px;
  border: 0.3px solid rgba(0, 0, 0, 0.1);
}

.sms-package {
  margin: 10px;
  border: 0.3px solid rgba(0, 0, 0, 0.1);
}
.sms-remaining {
  padding: 5px;
  margin: 10px;
  font-size: 16px;
}

.sms-pack > h3,
.sms-package > h3 {
  border-bottom: 0.5px solid #001529;
  margin: 0;
  padding: 5px;
  text-align: center;
}

.sms-pack-status {
  margin: 10px;
  padding-top: 0px;
  border: 1px solid seagreen;
}

.sms-pack-status-header {
  padding-top: 1px;
  text-align: center;
  background-color: #ea4b4a;
}

.sms-purchase {
  border: 9px;
  margin: 10px 20px 10px 20px;
}

.sms-purchase-info {
  /* padding: 10px; */
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 10px;
}

.sms-box {
  width: 160px;
  float: left;
  padding: 10px 5px;
  border: 0.3px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.payment-image {
  max-width: 100%;
  max-height: 60px;
  margin: 5px 2px;
}

.profile-tab .ui.secondary.menu {
  margin-bottom: -1px;
}

.profile-tab .ui.segment {
  margin-top: 0;
  border: none;
}

/* Payment Cancel Page */
.payment-container {
  margin: 30px;
  padding: 50px;
  height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 6px; */
}

.payment-cancel {
  border-radius: 5px;
  padding: 20px;
}

.payment-header {
  font-size: 4vw;
  color: #91dc5a;
  font-weight: 300;
  text-align: center;
}

.payment-header-error {
  font-size: 4vw;
  color: #d94b4b;
  font-weight: 300;
  text-align: center;
}

.payment-header-icon {
  width: 4vw;
  margin-right: 15px;
}

.payment-subheader {
  margin: 20px auto;
  font-size: 2vw;
  font-weight: 400;
  text-align: center;
}

#paragraph {
  font-size: 150%;
  font-weight: 300;
  text-align: center;
}

/* Payment Failed Page */
.payment-fail-container {
  padding: 30px;
  margin: 10px;
}

.payment-fail-cancel {
  border-radius: 5px;
  padding: 20px;
}

.payment-fail-header {
  font-size: 300%;
  color: #162c4a;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
}

#payment-fail-paragraph {
  font-size: 1.25 rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.6;
  margin-top: 5px;
}

.animation-spped-gauge {
  position: absolute;
  right: 100px;
  top: 13px;
  z-index: 999;
}
.display-none {
  display: none;
}
.display-block {
  display: block;
}
.green-circle {
  height: 12px;
  width: 12px;
  background-color: #40c057;
  border-radius: 50%;
  display: inline-block;
  margin-top: 2px;
  margin-right: 5px;
}
.red-circle {
  height: 12px;
  width: 12px;
  background-color: #fa5252;
  border-radius: 50%;
  display: inline-block;
  margin-top: 2px;
  margin-right: 5px;
}
.yellow-circle {
  height: 12px;
  width: 12px;
  background-color: #ffd43b;
  border-radius: 50%;
  display: inline-block;
  margin-top: 2px;
  margin-right: 5px;
}

/* Extra small devices (phones, 600px and down) */

/* Small devices (portrait tablets and large phones, 600px and up) */

.monthly-bill-table {
  margin-top: 33px;
  margin-right: 21px;
  margin-left: 21px;
  margin-bottom: 21px;
  background-color: #ffffff;
  padding-bottom: 20px;
  border-radius: 9px;
}

.bill-header {
  padding-top: 26px;
  margin-bottom: 25px;
}

.bill-text {
  font-size: 24px;
  font-weight: 100;
}

.cylinder {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  border-radius: 3px;
  background-color: rgba(160, 160, 160, 0.3);
}

.water {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 30px;
  border-radius: 3px;
  background-color: #ffa600;
  transition: 0.3s linear;
}

.fuel-info {
  text-align: center;
  line-height: 2;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  right: 0;
}

.dark-tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  color: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 400;
  padding: 2px 5px;
}

.leaflet-tooltip-left.dark-tooltip:before {
  border-left-color: rgba(0, 0, 0, 0.5);
}
.leaflet-tooltip-right.dark-tooltip:before {
  border-right-color: rgba(0, 0, 0, 0.5);
}
.leaflet-tooltip-top.dark-tooltip:before {
  border-top-color: rgba(0, 0, 0, 0.5);
}
.leaflet-tooltip-bottom.dark-tooltip:before {
  border-bottom-color: rgba(0, 0, 0, 0.5);
}

/* Center */
.center-of-page {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
}

.center-report {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrow-direction-icon {
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .date-time {
    display: block;
  }

  .react-datepicker__input-container input {
    margin-top: 12px;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .responsive-date-menu {
    display: none !important;
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

.select-semantic {
  outline: none;
  border: none;
  padding: 8px;
  background-color: white;
  border: 1px solid #ced4da;
  min-width: 185px;
}

.left-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#bkashButton {
  color: #fff !important;
  background-color: rgba(226, 19, 110, 0.8) !important;
}

#bkashButton:hover,
#bkashButton:focus {
  background-color: rgba(226, 19, 110, 1) !important;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.pay-with-img {
  width: 45%;
  display: inline-block;
}

.pay-with-text {
  width: 10%;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  #flex-center {
    justify-content: center;
  }

  .pay-with-text,
  .pay-with-img {
    width: 100%;
  }
}

.custom-divider {
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin: 3px 2px;
}

.custom-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

#selected-day {
  width: 145px;
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  #selected-day {
    margin-left: 10px;
    margin-top: 10px;
    width: 185px;
  }
}

#selected-day i {
  top: 5px !important;
}

#leaflet-map-container {
  height: calc(100vh - 200px);
  width: 100%;
  margin: 0;
  top: 0px;
  border-radius: 0;
}

.center-align-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crown-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 4px;
}

#map-box {
  height: calc(100vh - 40px);
  width: 100%;
  margin: 0;
  top: 0px;
  border-radius: 0;
}

.full-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
}

.sk-cube-grid {
  width: 50px;
  height: 50px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #cc0c15;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }

  35% {
    transform: scale3D(0, 0, 1);
  }
}

.speedBox {
  margin: 10px;
  width: 150px;
  height: 150px;
  color: red;
  border: 1px solid rebeccapurple;
  border-radius: 10px;
}

.legend {
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 6px 8px;
  width: 120px;
  line-height: 18px;
  color: #555;
}

.color-box {
  margin: 10px;
  width: 20px;
  height: 20px;
  background-color: red;
  border: 1px solid rebeccapurple;
}

